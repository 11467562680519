import React, { useEffect } from "react";
import Router, { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

import USER_QUERY from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import Layout from "@sellernote/shipda-kr/src/containers/Layout";
import LoginModal from "@sellernote/shipda-kr/src/containers/LoginModal";
import Seo from "@sellernote/shipda-kr/src/containers/Seo";
import { setNeedToKeepState } from "@sellernote/shipda-kr/src/utils/etc";

import { getServerSidePropsWithI18n } from "../../utils/i18n";

import Styled from "./index.styles";

export const getServerSideProps = getServerSidePropsWithI18n(["auth-new"]);

export default function Login() {
  const { query } = useRouter();

  // backTo에 queryString이 2개 이상 붙는 경우 전달이 안되어서 remainedQueryOfBackTo를 사용
  const { backTo, ...remainedQueryOfBackTo } = query;

  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const { refetch } = USER_QUERY.useGetUserInfo({
    enabled: false,
  });

  useEffect(() => {
    if (loggedIn) {
      refetch();

      let serializedRemainedQueryOfBackTo = "";
      if (remainedQueryOfBackTo) {
        for (const [key, value] of Object.entries(remainedQueryOfBackTo)) {
          serializedRemainedQueryOfBackTo += `&${key}=${value}`;
        }
      }

      if (backTo) {
        setNeedToKeepState("no");
        Router.replace(`${backTo}${serializedRemainedQueryOfBackTo}`);
      } else {
        Router.replace("/mypage");
      }
    }
  }, [loggedIn]);

  return (
    <Layout size="full" backgroundColor="#ffffff">
      <Seo
        path="/login"
        title={"로그인 - 디지털 수출입 포워더 쉽다"}
        description={
          "수출입 포워딩업무 디지털 전환의 시작. Digital freight forwarding - 쉽다와 쉽게 시작하세요."
        }
      />

      <Styled.container>
        <LoginModal active={true} isClickedLoginButton={true} />
      </Styled.container>
    </Layout>
  );
}
